<template>
  <div
    class="v_fun_task_index bbox"
  >
    <recommend
    ></recommend>
  </div>
</template>
<script>
import Recommend from './Recommend.vue'
export default {
  components: {
    Recommend
  },
  mounted(){
    this.$store.commit('set_chat_id', null);
    /**关闭添加联系人弹窗 */
    this.$api.common_close_add_friend_box();
    // 关闭编辑弹窗
    this.$store.commit('set_edit_task_box', false);
    /** 移除新类别红点 */
    this.$store.commit('set_new_task', false);
  },

}
</script>

<style lang="scss" scoped>
.v_fun_task_index{
  width: 100%;
  height: 100%;
  padding: 40px;
}
</style>

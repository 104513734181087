var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v_fun_task_recommend bbox" }, [
    _c(
      "div",
      { staticClass: "category_group d-flex align-stretch" },
      [
        _vm._l(_vm.category, function (item, index) {
          return [
            _c(
              "div",
              {
                key: index,
                staticClass:
                  "category bbox d-flex flex-column justify-space-between align-start",
              },
              [
                _c("div", { staticClass: "info_group" }, [
                  _c(
                    "div",
                    { staticClass: "name_group d-flex align-center pb-12" },
                    [
                      _c("img", {
                        staticClass: "icon mr-10",
                        attrs: {
                          src: require(`@/assets/images/recommend/${item.routername}.png`),
                          alt: "",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "name", style: { color: item.color } },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "descript pb-20" }, [
                    _vm._v(_vm._s(item.descript)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "button",
                    style: {
                      color: item.color,
                      "background-color": item.background,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.goToPannel(item.routername)
                      },
                    },
                  },
                  [_vm._v("点击进入" + _vm._s(item.name) + " >")]
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "vip_group un_sel d-flex align-center" }, [
      _c(
        "div",
        {
          staticClass:
            "user_group d-flex align-center justify-start bbox flex-shrink-0",
          class: { active: _vm.userinfo.userVip },
        },
        [
          _c("img", {
            staticClass: "avatar",
            attrs: {
              src: `${_vm.$avatar_url}${_vm.userinfo.thumbnail}@!small200`,
              alt: "",
              width: "80",
              height: "80",
            },
          }),
          _c(
            "div",
            { staticClass: "info_group d-flex flex-column align-start" },
            [
              _c("div", { staticClass: "info" }, [
                _c(
                  "div",
                  { staticClass: "name_group d-flex align-center flex-wrap" },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.userinfo.userName)),
                    ]),
                    _vm.userinfo.userVip
                      ? _c("img", {
                          staticClass: "vip",
                          attrs: {
                            src: require("@/assets/images/recommend/vip.png"),
                            alt: "",
                          },
                        })
                      : _c("div", { staticClass: "normal my-5 ml-4" }, [
                          _vm._v("普通用户"),
                        ]),
                  ]
                ),
                _vm.userinfo.vipEndDate && _vm.userinfo.userVip
                  ? _c("div", { staticClass: "expired_time pb-16" }, [
                      _vm._v(
                        _vm._s(
                          _vm
                            .$moment(_vm.userinfo.vipEndDate)
                            .format("YYYY-MM-DD")
                        ) + " 到期"
                      ),
                    ])
                  : _c("div", { staticClass: "expired_time pb-16" }),
              ]),
              _c(
                "div",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.touchVip()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.userinfo.userVip
                        ? "立即续费VIP会员"
                        : "立即成为VIP会员"
                    )
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "vip_list bbox d-flex align-stretch" }, [
        _c(
          "div",
          { staticClass: "list d-flex align-stretch flex-column" },
          [
            _vm._l(_vm.vip_list1, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "item d-flex align-center" },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require(`@/assets/images/personal_center/vip/vip${item.icon}.png`),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "descript" }, [
                      _vm._v(_vm._s(item.descript)),
                    ]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "list d-flex align-stretch flex-column" },
          [
            _vm._l(_vm.vip_list2, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "item d-flex align-center" },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require(`@/assets/images/personal_center/vip/vip${item.icon}.png`),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "descript" }, [
                      _vm._v(_vm._s(item.descript)),
                    ]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "list d-flex align-stretch flex-column" },
          [
            _vm._l(_vm.vip_list3, function (item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "item d-flex align-center" },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require(`@/assets/images/personal_center/vip/vip${item.icon}.png`),
                        alt: "",
                      },
                    }),
                    _c("div", { staticClass: "descript" }, [
                      _vm._v(_vm._s(item.descript)),
                    ]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass:
          "form_group bbox d-flex align-stretch justify-space-between",
      },
      [
        _vm._l(_vm.form_list, function (item, index) {
          return [
            _c(
              "div",
              {
                key: index,
                staticClass:
                  "list bbox d-flex flex-column justify-space-between align-start flex-shrink-0",
              },
              [
                _c("div", { staticClass: "info_group" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c("div", { staticClass: "descript" }, [
                    _vm._v(_vm._s(item.descript)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.touchApply({
                          enquireType: item.enquireType,
                          title: item.title,
                        })
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.button))]
                ),
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: require(`@/assets/images/recommend/${item.icon}.png`),
                    alt: "",
                  },
                }),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }